import React from 'react';

interface GlobalContainerProps {
  children: React.ReactNode;
  color?: string;
  className?: string;
}

const GlobalContainer: React.FC<GlobalContainerProps> = ({ children, color = "bg-white", className = '' }) => {
  return (
    <div className={color}>
      <div className='container'>
        <div className={className}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default GlobalContainer