import { Button } from "../ui/button"

const contentBuyer = [
  {
    title: "Convertissez vos confrères",
    badge: "10% de bon d’achat par filleul*",
    color: "secondary",
    arguments: [
      "Faites vivre vos producteurs",
      "Valorisez le local"
    ]
  },
  {
    title: "Faites migrer vos fournisseurs",
    badge: "10% de bon d’achat par filleul*",
    color: "primary",
    arguments: [
      "Centralisez vos achats",
      "Tarifs garantis"
    ]
  }
]

const contentProducer = [
  {
    title: "Convertissez vos confrères",
    badge: "gagnez 100€ par producteur*",
    color: "secondary",
    arguments: [
      "Valorisez la production locale",
      "Augmentez votre force de vente"
    ]
  },
  {
    title: "Invitez vos clients sur notre plateforme",
    badge: "gagnez 50€ par filleul*",
    color: "primary",
    arguments: [
      "Centralisez vos livraisons",
      "Garantissez vos prix"
    ]
  }
]

export default function Invite(props: {userType: string}) {
  const contentToReturn = props.userType === "buyer" ? contentBuyer : contentProducer

  return (
    <section className="md:px-10 md:pt-10 mt-36">
      <h4 className="text-center text-3xl md:text-4xl lg:text-5xl text-secondary-dark font-bold mb-4 max-w-2xl m-auto">
        Participez à la création d’un réseau national !
      </h4>
      <p className="text-center text-xl text-secondary-dark mb-6 max-w-2xl m-auto">
        Parrainez-vos confrères et vos producteurs
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        {contentToReturn.map((cb, i) => (
          <CardInvite 
            key={i}
            color={cb.color}
            title={cb.title}
            badge={cb.badge}
            arguments={cb.arguments}
          />
        ))}
      </div>
    </section>
  )
}

export function CardInvite(props: { color: string, title: string, badge: string, arguments: string[]}) {

  return (
    <div className={`rounded-xl bg-${props.color} px-5 py-8 sm:px-10 sm:py-10`}>
      <div className="flex items-center flex-col">
        <h5 className={`text-xl md:text-2xl lg:text-3xl ${props.color === 'secondary' ? 'text-white' : 'text-secondary-dark'} text-center font-bold mb-5`}>
          {props.title}
        </h5>
        <div className="bg-accent text-white text-center rounded-full px-7 py-1 mb-8">
          {props.badge}
        </div>
        {props.arguments.map((arg, i) => (
          <p key={i} className={`text-xl text-center ${props.color === 'secondary' ? 'text-white' : 'text-secondary-dark'} mb-4`}>
            {arg}
          </p>
        ))}
        <Button variant="secondary" className="mt-4" href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact">je parraine !</Button>
      </div>
    </div>
  )
}