import Footer from '../../components/footer';
import NavBar from '../../components/navigation/navBar';
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
// import Menu from '../../components/A-header/appBar'
// import Footer from '../../components/F-footer/footer'
// import { Box } from '@mui/material'

export default function Blog() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  

  return (
    <div>
      <div className='bg-secondary-lighterest pt-1 mt-[-8px] h-80'>
        <div className='container'>
          <NavBar />
        </div>
      </div>
      <div className='container'>
        <Outlet />
      </div>
      <Footer blog />
    </div>
  )
}