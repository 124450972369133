import { Outlet, useLocation } from 'react-router-dom'
import NavBar from '../../components/navigation/navBar';
import Footer from '../../components/footer';


export default function GlobalLayout() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasAcheteur = queryParams.get('de');

  return (
    <div>
      <div className='container absolute m-auto left-0 right-0 z-50'>
        <NavBar buyer={location.pathname === "/" || hasAcheteur === 'acheteur' ? true : false} />
      </div>
      <div>
        <Outlet />
      </div>
      <Footer 
        faq={location.pathname === "/faq" ? true : false}
        buyer={location.pathname === "/" || hasAcheteur === 'acheteur' ? true : false}
      />
    </div>
  )
}