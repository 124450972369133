import { Menu, Store, Tractor, X } from "lucide-react"
import logo from "../../assets/logos/logo.svg"
import { Button } from "../ui/button"
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTrigger } from "../ui/drawer"

export default function NavBar(props: {buyer?: boolean, blog?: boolean}) {

  return (
    <div className="py-4 px-5 md:px-10 flex justify-between items-center bg-primary rounded-lg mt-10">
      <div>
        <a href={props.buyer ? "/" : "/producteur"}>
          <img
            src={logo}
            className="h-10"
            alt="Mullo"
          />
        </a>
      </div>

      <div className="flex gap-4">
        {!props.buyer ? 
          <Button className="hidden lg:flex" variant="ghost" href="/"><Store className="mr-1" /> Je suis acheteur</Button> 
          : 
          <Button className="hidden lg:flex" variant="ghost" href="/producteur"><Tractor className="mr-1" /> Je suis producteur</Button>
        }
        <Button className="hidden lg:flex" href={`/faq?de=${props.buyer ? 'acheteur' : 'producteur'}`} variant="ghost">FAQ</Button>
        <Button className="hidden lg:flex" href="/blog" variant="ghost">Le blog</Button>
        <Button 
          className="hidden lg:flex" 
          variant="ghost"
          href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact"
        >
          Contact
        </Button>
        <Button className="hidden sm:flex" variant="destructive" href="https://appro.mullo.fr/inscription">Inscription</Button>
        <Button className="hidden sm:flex" variant="secondary" href="https://appro.mullo.fr/connexion">Connexion</Button>
        <MobileMenu buyer={props.buyer} />
      </div>
    </div>
  )
}



export function MobileMenu (props: {buyer?: boolean}) {
  return (
    <div className="block lg:hidden">
      <Drawer direction="right">
        <DrawerTrigger asChild>
          <Button variant="ghost"><Menu size={14} className="text-secondary-dark hover:text-white" /></Button>
        </DrawerTrigger>
        <DrawerContent>
          <div className="flex flex-1 flex-col">
            <DrawerHeader>
              <div>
                <DrawerDescription></DrawerDescription>
              </div>
              <div className="ml-10">
                <DrawerClose>
                  <Button variant="ghost" className="w-10 h-10 p-0 rounded-full">
                    <X size={32} />
                  </Button>
                </DrawerClose>
              </div>
            </DrawerHeader>
            <div className="p-4 pb-0 flex-1 flex flex-col gap-4">
              {!props.buyer ? 
                <Button className="w-full" variant="ghost" href="/"><Store className="mr-1" /> Je suis acheteur</Button> 
                : 
                <Button className="w-full" variant="ghost" href="/producteur"><Tractor className="mr-1" /> Je suis producteur</Button>
              }
              <Button className="w-full" href={`/faq?de=${props.buyer ? 'acheteur' : 'producteur'}`} variant="ghost">FAQ</Button>
              <Button className="w-full" href="/blog" variant="ghost">Le blog</Button>
              <Button  
                className="w-full"
                variant="ghost"
                href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact"
              >
                Nous contacter
              </Button>
            </div>
            <DrawerFooter>
              <Button className="w-full" variant="destructive" href="https://appro.mullo.fr/inscription">Inscription</Button>
              <Button className="w-full" variant="secondary" href="https://appro.mullo.fr/connexion">Connexion</Button>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  )
}



