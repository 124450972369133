import { Fragment, useEffect, useState } from 'react'
import APIServices from '../services/APIService'
import BlogArticle from '../../components/cards/blogArticle'
import { useParams } from 'react-router-dom'
import { Button } from '../../components/ui/button'
import { ChevronLeft } from 'lucide-react'

export default function CategoryArticles() {
  const { id, slug } = useParams()
  const [postsCat, setPostsCat] = useState(Array)

  useEffect(() => {
    const getAllPosts = async () => {
      const response = await APIServices.getAll(`posts?categories=${id}`)
      setPostsCat(response)
    }

    getAllPosts()
  }, [id])
  

  return (
    <div className='relative top-[-190px]'>
      <div className='container px-8 md:px-24 2xl:px-0'>
        <a href='/blog'>
          <Button variant='ghost' className='pl-1 mb-2'><ChevronLeft className="h-4 mr-2" /> Retour</Button>
        </a>
        <h1 className='text-3xl'>{slug && slug.charAt(0).toUpperCase() + slug.slice(1)}</h1>
      </div>

      <div className="container px-8 md:px-24 2xl:px-0">
        {postsCat.length > 0 ? postsCat.map((post, index) => (
          <Fragment key={index}>
            <BlogArticle post={post} />
            <hr className="my-6" />
          </Fragment>
        )) : null}
      </div>
    </div>
  )
}