import HomeSection from "../../components/sections/home";
import GlobalContainer from "../../components/ui/globalContainer";
import VisualPlatfom from "../../assets/images/visuals/platform-visual.png"
import ProductsSection from "../../components/sections/products";
import Delivery from "../../components/sections/delivery";
import Invite from "../../components/sections/invite";


export default function Buyer() {
  
  return (
    <div>
      <GlobalContainer color='bg-secondary-lighterest' className="md:px-10">
        <HomeSection userType="buyer" />
      </GlobalContainer>
      <GlobalContainer color='bg-secondary-lighterest' className="md:px-10 py-20">
        <h2 className="md:w-2/3 text-4xl sm:text-5xl text-secondary-dark font-bold mb-4">Commandez en ligne en un rien de temps</h2>
        <p className="md:w-2/3 text-md md:text-xl text-secondary-dark mb-6">
          Retourver l'ensemble de l'offre de produits en circuit court sur une seule platefome
        </p>
        <div className="mt-[-30px] md:mt-[-80px] lg:mt-[-110px] max-w-screen-xl lg:pr-32">
          <img src={VisualPlatfom} alt="plateforme Mullo" />
        </div>
      </GlobalContainer>
      <GlobalContainer className="py-36">
        <ProductsSection />
        <div className="pt-64">
          <Delivery userType="buyer" />
        </div>
        <Invite userType="buyer" />
      </GlobalContainer>
    </div>
  )
}