import { useEffect, useState } from "react"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion"
import APIServices from "../../app/services/APIService"

interface ContentModel {
  title: {
    rendered: string;
  };
  acf: {
    reponse: string;
    destinataire?: string;
  }
}

export default function FaqCard(props: {userType?: string}) {
  const [content, setContent] = useState<ContentModel[]>([])

  useEffect(() => {
    const getAllPosts = async () => {
      const response = await APIServices.getAll(`faq?per_page=40`)
      const wichReturn = props.userType === "buyer" ? "Acheteurs" : "Producteurs"
      const filteredProducts = Array.isArray(response) ? response.filter(item => item.acf.destinataire === wichReturn) : []
      setContent(filteredProducts)
    }

    getAllPosts()
  }, [props.userType])

  return (
    <div className="bg-secondary-lighterest rounded-xl px-10 py-12 flex flex-col lg:flex-row mt-12 mb-5">
      <div className="max-w-sm mb-6">
        <h4 className="text-3xl md:text-4xl font-bold text-secondary-dark mb-4">
          {props.userType === "buyer" ? "Je suis acheteur" : "Je suis producteur"}
        </h4>
        <p>
          {props.userType === "buyer" ? 
            "J’ai besoin de produits alimentaires pour mon activité et je souhaite me fournir en direct producteur." 
          : 
            "Je produits des denrées alimentaires et je souhaite les vendre au meilleur prix sans perdre de temps en commercialisation et en logistique."
          }
        </p>
      </div>
      <div className="flex-1 lg:pl-10">
        <Accordion type="single" collapsible>
          {content.map((cb, i) => (
            <AccordionItem key={i} value={`item-${i}`}>
              <AccordionTrigger>
                <span dangerouslySetInnerHTML={{ __html: cb?.title?.rendered }} />
              </AccordionTrigger>
              <AccordionContent>
                <div dangerouslySetInnerHTML={{ __html: cb?.acf?.reponse }} />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  )
}