import FaqCard from "../../components/cards/cardFaq";
import GlobalContainer from "../../components/ui/globalContainer";

export default function FAQ() {
  
  return (
    <div>
      <GlobalContainer className="md:px-10 pt-44">
        <h2 className="md:w-4/5 lg:w-2/3 text-4xl sm:text-5xl text-secondary-dark font-bold mb-4">
          Notre FAQ
        </h2>
        <p className="md:w-4/5 lg:w-2/5 text-md md:text-xl text-secondary-dark mb-6">
          Vous avez une question ?<br/>Nous sommes là pour vous aider !
        </p>
      </GlobalContainer>
      <GlobalContainer>
        <FaqCard userType="buyer" />
        <FaqCard userType="producer" />
      </GlobalContainer>
    </div>
  )
}