import { useState } from "react"
import DeliveryBike from "../../assets/images/svg/delivery-bike.png"
import DeliveryProducer from "../../assets/images/svg/DeliveryProducre.svg"

const contentBuyer = [
  {
    id: 0,
    title: "Équitable",
    subTitle: "Des marges surveillées",
    text: "Notre commission sur vente et les frais de livraison comprennent les services des différentes parties prenantes de la logistique.<br/><br/>En moyenne 18% de commission sont appliquées (contre 20% à 50% sur les marché de gros)",
    color: "accent"
  },
  {
    id: 1,
    title: "Frais",
    subTitle: "Des produits frais pour les professionnels de l'alimentaire",
    text: "Notre plateforme B2B de vente en directe facilite l’accès aux producteurs locaux.<br/><br/>La commande en ligne de produits locaux chez Mullo vous garantie une qualité supérieure et la traçabilité des produits.",
    color: "primary-dark"
  },
  {
    id: 2,
    title: "Écologique",
    subTitle: "Alimentation durable et responsable",
    text: "La réduction de l'impact environnemental dans la logistique alimentaire est au coeur de nos préoccupation.<br/><br/>Notre stratégies d'approvisionnement responsable en plus d’avoir un impact positif garantie un approvisionnement fiable et des coûts de livraison réduits.",
    color: "secondary"
  }
]

const contentProducer = [
  {
    id: 0,
    title: "Optimisation",
    subTitle: "Un large maillage d'acteurs et d'acheteurs",
    text: "La mutualisation des clients est au coeur de notre expertise. Augmenter le nombre d’acheteurs professionnels c’est aussi booster la rentabilité.<br/><br/>Notre réseau donne la possibilité de déposer au plus près du lieu de production les produits commandés.<br/><br/>Vous êtes loin d’un dépôt ? Pas de panique on trouve toujours une solution",
    color: "accent"
  },
  {
    id: 1,
    title: "Mutualisation",
    subTitle: "Marché ultra local en ligne",
    text: "Notre solution d’approvisionnement alimentaire B2B repose sur l’optimisation des flux logistiques afin de garantir des produits frais à prix abordables tout en renforçant la rentabilité des producteurs.<br/><br/>Nous organisons des points de collecte au plus proche des exploitations afin de faciliter la livraison de produits frais.",
    color: "primary-dark"
  },
  {
    id: 2,
    title: "Écologique",
    subTitle: "Stratégies d'approvisionnement responsable",
    text: "Les circuits courts professionnels sont indispensables pour assurer la rentabilité des producteurs locaux et pour favoriser l’approvisionnement local.<br/><br/>Mullo met en avant les certifications (bio, label rouge, AOP, IGP...) afin de garantir une transparence de la chaîne d'approvisionnement et de valoriser l'identité régionale",
    color: "secondary"
  }
]
export default function Delivery(props: { userType?: string }) {
  const [contentSelect, setContentSelect] = useState(0)
  const contentToReturn = props.userType === "buyer" ? contentBuyer : contentProducer

  return (
    <div>
      <div className="relative md:p-10 text-pr">
        <h3 className="text-4xl lg:text-5xl text-secondary-dark font-bold w-full sm:w-2/3 md:w-3/5 mb-4">
          Commandes livrées par nos prestataires locaux
        </h3>
        <p className="text-xl text-secondary-dark w-full sm:w-2/3 md:w-1/2 lg:w-1/3 lg:mb-5">
          Mullo s’interface avec les acteurs locaux pour des livraisons fiables et rapides
        </p>
        <img 
          className="hidden md:block absolute top-0 right-0 w-3/5 lg:w-6/12 xl:w-2/5" 
          src={props.userType === "buyer" ? DeliveryBike : DeliveryProducer} 
          alt="Livraison simple et optimisée"
        />
      </div>

      <div className="mt-10 sm:mt-0 bg-secondary-lighterest px-5 py-8 sm:px-10 sm:py-10 rounded-xl">
        <div className="hidden sm:grid grid-cols-2 gap-2 pt-16 md:pt-32 lg:pt-36">
          <div>
            {contentToReturn.map((c, i) => {
              const color = i === 0 ? "text-accent" : i === 1 ? "text-primary-dark" : "text-secondary"
              return (
                <h4 
                  key={i}
                  className={`text-4xl lg:text-6xl ${color} font-bold ${i === contentSelect ? "underline" : "opacity-80"} mb-16 lg:mb-20 hover:cursor-pointer hover:opacity-100`}
                  onClick={() => setContentSelect(i)}
                >
                  {c.title}
                </h4>
              )
            })}

          </div>
          <div className="flex flex-col justify-center mb-14">
            <h5 className={`text-2xl font-bold text-${contentToReturn[contentSelect].color} mb-5`}>
              {contentToReturn[contentSelect].subTitle}
            </h5>
            <p className="text-xl text-secondary-dark" dangerouslySetInnerHTML={{ __html: contentToReturn[contentSelect].text }}/>
          </div>
        </div>

        <div className="block sm:hidden">
          {contentToReturn.map((c, i) => (
            <div key={i}>
              <h4 className={`text-3xl text-${c.color} font-bold`}>
                {c.title}
              </h4>
              <h5 className={`text-xl font-bold text-${c.color} mb-3 mt-1`}>
                {c.subTitle}
              </h5>
              <p className="text-lg text-secondary-dark mb-10" dangerouslySetInnerHTML={{ __html: c.text }}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}