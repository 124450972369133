import { Fragment, useEffect, useState } from 'react'
import APIServices from '../services/APIService'
import BlogArticle from '../../components/cards/blogArticle'
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination"
import InfrontArticle from '../../components/cards/infrontArticle'

export default function BlogMain() {
  const [posts, setPosts] = useState(Array)
  const [inFrontPost, setInFrontPost] = useState(Object)
  const [pageNumber, setPageNumber] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState(1)

  useEffect(() => {
    const getAllPosts = async () => {
      const response = await APIServices.getAll(`posts?page=${pageNumber}`, true)
      if (pageNumber === 1) setInFrontPost(response.data[0])
      setPosts(pageNumber === 1 ? response.data.slice(1) : response.data)
    setNumberOfPages(Number(response.headers['x-wp-totalpages']))
    window.scrollTo(0,pageNumber === 1 ? 0 : 550)
  }
  
  getAllPosts()
}, [pageNumber])

  return (
    <>
      <div className="mb:px-10 relative top-[-160px]">
        {Object.keys(inFrontPost).length > 0 && <InfrontArticle post={inFrontPost} />}

        {posts.length > 0 ? posts?.map((post, index) => (
          <Fragment key={index}>
            <BlogArticle post={post} />
            <hr className="my-6" />
          </Fragment>
        )) : null}

        <Pagination>
          <PaginationContent>

            <PaginationItem>
              <PaginationPrevious onChange={() => setPageNumber(pageNumber > 1 ? pageNumber-1 : 1)} />
            </PaginationItem>

            {Array.from(Array(numberOfPages), (page, index) => {
              return (
                <PaginationItem key={index}>
                  <PaginationLink onChange={() => setPageNumber(index+1)}  isActive={pageNumber===index+1}>{index+1}</PaginationLink>
                </PaginationItem>
              )
            })}

            <PaginationItem>
              <PaginationNext onChange={() => setPageNumber(pageNumber+1)} />
            </PaginationItem>

          </PaginationContent>
        </Pagination>
      </div>
    </>
  )
}