import ProductCard from "../cards/product"
import France from '../../assets/images/svg/france.png'
import { useEffect, useState } from "react"

const products = [
  {
    image: "https://olivetsens.com/wp-content/uploads/2021/04/HUILE_BOUTEILLAN-min-scaled-e1617887290672.jpg",
    title: "Huile d'olive",
  },
  {
    image: "https://rozho-blog-yby1k94dz0.live-website.com/wp-content/uploads/2025/02/yaourt-nature.png",
    title: "Yaourt nature",
  },
  {
    image: "https://olivetsens.com/wp-content/uploads/2021/04/Asperges-scaled.jpg",
    title: "Délice d'asperges",
  },
  {
    image: "https://nemrod.co/cdn/shop/files/TerrinedeSanglieralaTruffedEte100gr_1024x1024@2x.jpg?v=1728913537",
    title: "Terrine de sanglier",
  },
  {
    image: "https://rozho-blog-yby1k94dz0.live-website.com/wp-content/uploads/2025/02/caviar.png",
    title: "Caviar végétal",
  },
  {
    image: "https://rozho-blog-yby1k94dz0.live-website.com/wp-content/uploads/2025/02/rkombuchaProduct-1024x488-1.jpg",
    title: "Kombucha",
  },
]

export default function ProductsSection(props: { userType?: string }) {
  const [toSlice, setToSlice] = useState(6)

  const updateBreakpoint = () => {
    const width = window.innerWidth;

    if (width < 640) {
      setToSlice(2);
    } else if(width >= 640 && width < 768) {
      setToSlice(3);
    } else if (width >= 768 && width < 1024) {
      setToSlice(4);
    } else if (width >= 1024 && width < 1280) {
      setToSlice(5);
    } else {
      setToSlice(6);
    }
  };

  useEffect(() => {
    updateBreakpoint(); // Vérifie au chargement
    window.addEventListener("resize", updateBreakpoint); // Vérifie au resize

    return () => window.removeEventListener("resize", updateBreakpoint);
  }, []);

  return (
    <div>
      <div className="sm:bg-primary rounded-xl sm:px-10 h-60">
        <div className="relative top-[-25px] md:top-[-30px] lg:top-[-45px] h-80">
          <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl text-secondary-dark font-bold mb-5">Notre gamme nationale</h3>
          <div className="grid gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
            {products.slice(0,toSlice).map((prod, index) => (
              <div key={index}>
                <ProductCard 
                  image={prod.image}
                  title={prod.title}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <a href="https://appro.mullo.fr/boutique/org_2sfLSwpw3eOD3uUuvBWqQVaqMrA/produits" className="block text-2xl text-right text-secondary-dark underline font-bold sm:pr-10 mt-16">
        voir les +200 références
      </a>


      <div className="relative bg-secondary-lighterest mt-28 rounded-xl px-5 py-8 sm:px-10 sm:py-10">
        <img 
          src={France}
          className="absolute left-[-80px] sm:left-[-30px] lg:top-[-50px] h-[400px] md:h-[500px] lg:h-auto object-contain"
          alt="La France de Mullo"
        />
        <div className="relative flex items-center justify-end h-64 sm:h-96">
          <div className="w-full sm:w-3/4 lg:w-2/3">
            <h3 className="text-4xl md:text-5xl xl:text-7xl mt-[-10px] text-secondary-dark font-bold text-right mb-4">Nos gammes locales</h3>
            <p className="text-md md:text-xl text-secondary-dark mb-6 text-right">
              Les produits locaux de votre région à porter de main
            </p>
            <a href="https://appro.mullo.fr/connexion" className="hover:cursor-pointer block text-2xl text-right text-secondary-dark underline font-bold">
              connectez-vous
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}