import Full from "../../assets/images/svg/mullo-full.png"
import topMobile from "../../assets/images/svg/groupe-prod.png"
import { Button } from "../ui/button"

export default function HomeSection(props: { userType?: string }) {

  return (
    <section className="relative min-h-screen flex flex-col-reverse md:flex-row justify-center md:justify-between items-center gap-4 pt-32 pb-5">

      <div>
        <h1 className="text-2xl sm:text-4xl xl:text-5xl xl:pr-44 text-secondary-dark font-bold mb-4">
          {props.userType === "buyer" ?
            "La solution logistique pour les achats alimentaires locaux"
            :
            "La solution logistique pour les circuits courts professionnels"
          }
        </h1>
        <p className="text-md md:text-xl xl:pr-44 text-secondary-dark mb-6">
          {props.userType === "buyer" ?
            "L’unification des producteurs et des acteurs locaux sur une même plateforme de vente en circuit court pour les professionnels"
            :
            "Augmentez votre force de vente en centralisant des échanges alimentaires en ligne"
          }
        </p>
        <Button variant="secondary" href={props.userType === "buyer" ? "https://appro.mullo.fr" : "https://appro.mullo.fr/inscription"}>
          {props.userType === "buyer" ? "passer commande" : "rejoindre notre réseau"}
        </Button>
      </div>


      <div>
        <div className="md:hidden">
          <img
            src={topMobile}
            className="mb-5"
            alt="Regroupement producteurs"
          />
        </div>

        <div className="hidden md:block">
          <img
            src={Full}
            className="object-contain max-w-sm lg:max-w-md xl:max-w-xl"
            alt="Regroupement producteurs"
          />
        </div>
      </div>


    </section>
  )
}