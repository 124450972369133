import HomeSection from "../../components/sections/home";
import GlobalContainer from "../../components/ui/globalContainer";
import Delivery from "../../components/sections/delivery";
import Invite from "../../components/sections/invite";
import Pltaform from "../../assets/images/visuals/platform.png"

export default function Producer() {
  
  return (
    <div>
      <GlobalContainer color='bg-secondary-lighterest' className="md:px-10">
        <HomeSection userType="producer" />
      </GlobalContainer>
      <GlobalContainer color='bg-secondary-lighterest' className="md:px-10 py-20">
        <h2 className="md:w-4/5 lg:w-2/3 text-4xl sm:text-5xl text-secondary-dark font-bold mb-4">
          Vendez en ligne depuis vos outils préférés
        </h2>
        <p className="md:w-4/5 lg:w-2/5 text-md md:text-xl text-secondary-dark mb-6">
          Optez pour la centralisation des échanges alimentaires en ligne et l’optimisation de vos flux logistiques
        </p>
        <a href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact" className="text-2xl underline font-bold text-secondary-dark">demandez une intégration</a>
        <div className="flex lg:justify-end lg:mt-[-250px] xl:mt-[-300px]">
          <img src={Pltaform} alt="Intégration d'outils existants" className="md:max-w-3xl lg:max-w-xl xl:max-w-4xl" />
        </div>
      </GlobalContainer>
      <GlobalContainer className="py-36">
        <Delivery userType="producer" />
        <Invite userType="producer" />
      </GlobalContainer>
    </div>
  )
}