import { Button } from "../ui/button"
import IcoMullo from "../../assets/logos/ico-mullo-full-bg-white.svg"
import IcoMulloWhite from "../../assets/logos/ico-mullo-white.svg"
import CGV from '../../assets/pdf/cgv.pdf'
import CGU from '../../assets/pdf/cgu.pdf'

export default function Footer(props: {faq?: boolean, blog?: boolean, buyer?: boolean}) {

  return (
    <div>
      <div className={`${props.blog && 'hidden'} container flex flex-col md:flex-row justify-between items-center mb-[-70px] md:mb-[-100px] lg:mb-[-150px] xl:mb-[-200px]`}>
        <div className="md:mb-10 lg:mb-0 md:pl-10">
          <h6 className="text-4xl lg:text-6xl font-bold mb-4 text-secondary-dark max-w-sm md:max-w-lg">
            {props.faq ? 
              "Vous n’avez pas trouvé réponse à votre question ?"
              : 
              "Une question ?"
            }
          </h6>
          <p className="text-xl text-secondary-dark mb-5">
            {props.faq ? 
              "Contactez-nous sans plus attendre"
              : 
              "Notre FAQ est là pour vous répondre"
            }
          </p>
          <Button 
            variant="secondary" 
            href={props.faq ? "https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact" : `/faq?de=${props.buyer ? 'acheteur' : 'producteur'}`}
          >
            {props.faq ? "nous contacter" : "consulter"}
          </Button>
        </div>
        <img
          src={IcoMullo}
          className="w-full md:w-1/2"
          alt="logo Mullo"
        />
      </div>
      <div className="bg-secondary-dark pt-24 md:pt-10">
        <div className="container">
          <div className="md:p-10 sm:w-2/3 md:w-1/2">
            <img
              src={IcoMulloWhite}
              className="w-36 mb-4"
              alt="logo Mullo"
            />
            <p className="text-xl font-bold text-white mb-5">La solution de commercialisation pour<br/>les professionnels du circuit court</p>
            <Button href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact">nous contacter</Button>
          </div>

          <div className="flex flex-col sm:flex-row justify-between items-start md:items-center py-10 md:px-10">
            <div className="flex flex-col md:flex-row gap-4">
              <a className="text-white text-sm hover:underline" href={CGV} title="Pdf" download="cgv-mullo.pdf">nos conditions générales de vente</a>
              <a className="text-white text-sm hover:underline" href={CGU} title="Pdf" download="cgU-mullo.pdf">nos conditions générales d’utilisation</a>
              <a className="text-white text-sm hover:underline" href={`/faq?de=${props.buyer ? 'acheteur' : 'producteur'}`}>notre FAQ</a>
            </div>
            <p className="mt-10 sm:mt-0 text-md text-white">droits réservés @mullo</p>
          </div>
        </div>
      </div>
    </div>
  )
}



