interface ProductCardProps {
  // Définissez ici les propriétés que vous souhaitez ajouter
  title?: string;
  image?: string;
}

export default function ProductCard({ title, image }: ProductCardProps) {

  return (
    <div 
      className={`relative rounded-xl flex justify-center items-end h-60 overflow-hidden`}>
      <img className="absolute min-h-full min-w-full object-cover" src={image} alt={title} />
      <h4 className={`relative text-xl font-bold text-white mb-4 px-2 text-center`}>
        {title}
      </h4>
    </div>
  )
}