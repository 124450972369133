import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Team from './components/team';
import Member from './components/team/member';
import GlobalLayout from './app/pages/layout';
import Buyer from './app/pages/buyer';
import Producer from './app/pages/producer';
import FAQ from './app/pages/faq';
import BlogLayout from './app/blog';
import BlogMain from './app/blog/blogMain';
import SingleArticle from './app/blog/singleArticle';
import CategoryArticles from './app/blog/categoryArticle';

const App: React.FC = () => {

  return (
    <Router>
      <Routes>
        {/* GENERAL */}
        {/* <Route path='*' Component={NotFound} /> */}
        <Route path='/' element={<GlobalLayout />}>
          <Route index element={<Buyer />} />
          <Route path='producteur' element={<Producer />} />
          <Route path='faq' element={<FAQ />} />
        </Route>

        {/* BLOG */}
        <Route path='/blog' element={<BlogLayout />} >
          <Route path="" element={<BlogMain />}/>
          <Route path=":id/:slug" element={<SingleArticle />}/>
          <Route path="categories/:id/:slug" element={<CategoryArticles />}/>
        </Route>

        {/* TEAM MEMBERS */}
        <Route path='/equipe' element={<Team />} >
          <Route path=':teamId/:slug' element={<Member />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
